<i18n>
  {
    "es": {
      "FINISH_AT": "Terminan en",
      "DAYS": "día | días",
      "HOURS": "hora | horas",
      "MINUTES": "minuto | minutos",
      "SECONDS": "segundo | segundos"
    }
  }  
</i18n>

<template>
  <div>
    <div class="flex flex-col relative w-full max-w-1400 mx-auto -mb-[7.8em]">
      <div class="flex items-start gap-x-3">
        <img
          src="@/assets/svg/icons/icons-flower.svg"
          alt="Icono de flor"
          class="ml-10 md:ml-6 w-6 h-6 mt-1"
        />
        <div class="flex flex-col">
          <span
            class="text-xl lg:text-2xl font-semibold font-bodoni tracking-wider"
          >
            {{ blok.title }}
          </span>
          <span
            v-if="blok.countdown"
            class="mt-1 uppercase text-xxs md:text-xs font-bold tracking-[0.15em] text-sanremo-gray leading-5"
            >{{ t('FINISH_AT') }} {{ countdown }}</span
          >
        </div>
      </div>
      <div
        class="ml-4 md:ml-0 w-1 h-16 bg-[#3E5391] absolute top-1 left-2 z-10"
      ></div>
    </div>

    <div
      class="w-full max-w-1400 flex items-center gap-x-4 md:mx-auto mt-32 mdplus:mt-28"
      :class="isMobileOrTablet ? 'md:px-2 lg:px-0' : 'md:px-9'"
    >
      <div v-if="!isMobileOrTablet">
        <BrandedProductSliderImage :blok="blok.image[0]" />
      </div>
      <div class="overflow-hidden">
        <BrandedProductSliderProducts
          :blok="blok.products[0]"
          :currency="currency"
          :global="global"
          class="mdplus:-mr-3"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  blok: Object,
  currency: Object,
  global: Object,
})

const { t } = useI18n()
const { isMobileOrTablet } = useDevice()
const countdown = ref('')

const calculateCountdown = () => {
  if (!props.blok.countdown) return
  const targetDate = new Date(props.blok.countdown)
  const now = new Date()

  let difference = targetDate - now

  if (difference < 0) {
    countdown.value = `0 ${t('DAYS', 0)} 00 ${t('HOURS', 0)} 00 ${t('MINUTES', 0)} 00 ${t('SECONDS', 0)}`
    return
  }

  const days = Math.floor(difference / (1000 * 60 * 60 * 24))
  difference -= days * 1000 * 60 * 60 * 24

  const hours = Math.floor(difference / (1000 * 60 * 60))
  difference -= hours * 1000 * 60 * 60

  const minutes = Math.floor(difference / (1000 * 60))
  difference -= minutes * 1000 * 60

  const seconds = Math.floor(difference / 1000)

  countdown.value = `${days} ${t('DAYS', days)} ${hours
    .toString()
    .padStart(2, '0')} ${t('HOURS', hours)} ${minutes
    .toString()
    .padStart(
      2,
      '0'
    )} ${t('MINUTES', minutes)} ${seconds.toString().padStart(2, '0')} ${t('SECONDS', seconds)}`
}

onMounted(() => {
  if (props.blok.countdown) {
    setInterval(() => {
      calculateCountdown()
    }, 1000)
  }
})
</script>
